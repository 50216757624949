import { GetStaticProps } from 'next'
import { pageUtil } from '@/services/api/pageUtil'

export const getStaticProps: GetStaticProps = async () => {
  return pageUtil.getCampaignPageProps({
    campaignSlug: 'angel-2',
    domain: 'angel-reg-a.com',
  })
}

export { default } from '@/components/pages/CampaignPages/CampaignPage'
